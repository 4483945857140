<template>
  <div>
    <b-modal
      centered
      id="seat-layout-modal"
      size="xl"
      title="Seat Layout"
      hide-footer
    >
      <div class="canvas-container">
        <canvas id="canvasId"></canvas>
        <div class="canvas-actions">
          <b-card class="mb-1">
            <div class="d-flex mb-1" style="gap: 8px">
              <div class="circle grey"></div>
              User Blocked Seats
            </div>

            <div class="d-flex mb-1" style="gap: 8px">
              <div class="circle red"></div>
              Admin Blocked Seats
            </div>

            <div class="d-flex mb-1" style="gap: 8px">
              <div class="circle green"></div>
              Selected Seats
            </div>

            <div class="d-flex" style="gap: 8px">
              <div class="circle" style="border: 1.5px solid black"></div>
              Released Seats
            </div>
          </b-card>
          <b-card>
            <div class="d-flex" style="gap: 10px">
              <b-button
                variant="outline-primary"
                class="w-100"
                @click="hideSeatLayoutModal"
                >Cancel</b-button
              >
              <b-button
                variant="primary"
                class="w-100"
                @click="saveModifiedSeatLayout"
                >Confirm
              </b-button>
            </div>
          </b-card>
        </div>
      </div>
    </b-modal>
    <div>
      <div>
        <h5>
          <b-badge pill variant="dark" show>
            <b>{{ form.type.toUpperCase() }}</b>
          </b-badge>
        </h5>
      </div>
      <b-card>
        <b-card-text>
          <b-row>
            <b-col cols="12" md="4"
              ><b-form-group label="Cinema/Theatre">
                <v-select
                  v-model="form.event_cinema_id"
                  placeholder="Select Cinema/Theatre"
                  :clearable="false"
                  label="cinema_name"
                  value="cinema_id"
                  :reduce="(cinema) => cinema.cinema_id"
                  :options="cinemaListArray"
                >
                  <template slot="selected-option" slot-scope="option">
                    <div class="flex">
                      {{ option.cinema_name }} , {{ option.city_name }} ,
                      {{ option.country_name }}
                    </div>
                  </template>
                  <template slot="option" slot-scope="option">
                    <span class="fa" :class="option.icon"></span>
                    {{ option.cinema_name }} , {{ option.city_name }} ,
                    {{ option.country_name }}
                  </template>
                  <!-- <template v-slot:option="option">
                  <span></span>
                  {{ option.cinema_name }} , {{ option.city_name }} ,
                  {{ option.country_name }}
                </template> -->
                </v-select>
              </b-form-group></b-col
            >
            <b-col cols="12" md="4"
              ><b-form-group label="Name">
                <b-form-input
                  v-model="form.event_name"
                  id="input-default"
                  placeholder="Name"
                ></b-form-input> </b-form-group
            ></b-col>

            <b-col cols="12" md="4"
              ><b-form-group label="Booking Code Prefix">
                <b-form-input
                  v-model="form.event_prefix_code"
                  id="input-default"
                  placeholder="Booking Code Prefix"
                ></b-form-input> </b-form-group
            ></b-col>

            <b-col cols="12" md="4"
              ><b-form-group label="Start Date">
                <flat-pickr
                  v-model="form.event_start_date"
                  placeholder="Start Date"
                  class="form-control"
                /> </b-form-group
            ></b-col>
            <b-col cols="12" md="4"
              ><b-form-group label="End Date">
                <flat-pickr
                  v-model="form.event_end_date"
                  placeholder="End Date"
                  class="form-control"
                /> </b-form-group
            ></b-col>
            <b-col cols="12" md="4"
              ><b-form-group label="Age Limit">
                <b-form-input
                  v-model="form.event_age_limit"
                  id="input-default"
                  placeholder="Age Limit"
                ></b-form-input></b-form-group
            ></b-col>
            <b-col cols="12" md="4"
              ><b-form-group label="Genre">
                <v-select
                  v-model="form.event_genre_ids"
                  multiple
                  placeholder="Select Genre"
                  label="genre_name"
                  value="genre_id"
                  :reduce="(genre) => genre.genre_id"
                  :options="genreListArray"
                /> </b-form-group
            ></b-col>
            <b-col cols="12" md="4"
              ><b-form-group label="Language">
                <v-select
                  v-model="form.event_language_ids"
                  multiple
                  placeholder="Select Language"
                  label="lang_name"
                  value="lang_id"
                  :reduce="(language) => language.lang_id"
                  :options="languageListArray"
                /> </b-form-group
            ></b-col>
            <b-col cols="12" md="4"
              ><b-form-group label="Seat Selection Limit">
                <b-form-input
                  type="number"
                  v-model="form.selectable_max_seats"
                  id="input-default"
                  placeholder="Seat Selection Limit"
                ></b-form-input></b-form-group
            ></b-col>

            <b-col cols="12" md="4"
              ><b-form-group label="Booking Fee (percent)">
                <b-form-input
                  type="number"
                  v-model="form.event_booking_fees"
                  id="input-default-fees"
                  placeholder="Booking Fee %"
                ></b-form-input></b-form-group
            ></b-col>
            <b-card-group class="m-1" deck>
              <b-card
                border-variant="primary"
                :img-src="
                  form.event_image_small
                    ? BASE_URL + form.event_image_small
                    : ''
                "
                img-alt="Small image"
                img-bottom
              >
                <b-card-text>
                  <b-form-group label="Main Image (416 x 481)">
                    <b-form-file
                      @input="onFileUpload('small', image_small_model)"
                      v-model="image_small_model"
                      plain
                    ></b-form-file>
                  </b-form-group>
                </b-card-text>
              </b-card>

              <b-card
                :img-src="
                  form.event_image_medium
                    ? BASE_URL + form.event_image_medium
                    : ''
                "
                img-alt="Medium image"
                img-bottom
                border-variant="primary"
              >
                <b-card-text>
                  <b-form-group label="Detail Image(1920 x 500)">
                    <b-form-file
                      @input="onFileUpload('medium', image_medium_model)"
                      v-model="image_medium_model"
                      plain
                    ></b-form-file>
                  </b-form-group>
                </b-card-text>
              </b-card>
              <b-card
                :img-src="
                  form.event_image_large
                    ? BASE_URL + form.event_image_large
                    : ''
                "
                img-alt="Large image"
                img-bottom
                border-variant="primary"
              >
                <b-card-text>
                  <b-form-group label="Banner Image(1920 x 1080)">
                    <b-form-file
                      @input="onFileUpload('large', image_large_model)"
                      v-model="image_large_model"
                      plain
                    ></b-form-file>
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-card-group>

            <b-col cols="12" md="6"
              ><b-form-group label="Short Description">
                <quill-editor v-model="form.event_short_description">
                </quill-editor></b-form-group
            ></b-col>
            <b-col cols="12" md="6"
              ><b-form-group label="Long Description">
                <quill-editor v-model="form.event_long_description">
                </quill-editor> </b-form-group
            ></b-col>

            <b-col cols="12" md="6"
              ><b-form-group label="Terms & Condition">
                <quill-editor v-model="form.event_tnc">
                </quill-editor> </b-form-group
            ></b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Managers (for scanning QR tickets)">
                <v-select
                  v-model="form.event_managers_ids"
                  multiple
                  placeholder="Select Manager"
                  label="first_name"
                  value="user_id"
                  :reduce="(user) => user.user_id"
                  :options="userListArray"
                >
                  <template slot="selected-option" slot-scope="option">
                    <div class="flex">
                      {{ option.first_name }} {{ option.last_name }} (
                      {{ option.email }})
                    </div>
                  </template>
                  <template slot="option" slot-scope="option">
                    <span class="fa" :class="option.icon"></span>
                    {{ option.first_name }} {{ option.last_name }} (
                    {{ option.email }})
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group label="Seating Type" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  :disabled="form.event_id ? true : false"
                  @change="onSelectSeatingType"
                  v-model="form.event_seating_type"
                  id="radio-group-2"
                  :aria-describedby="ariaDescribedby"
                  name="radio-sub-component"
                >
                  <b-form-radio value="N">Normal Seating</b-form-radio>
                  <b-form-radio value="Y"
                    >Seat Layout<b>(internal)</b></b-form-radio
                  >
                  <b-form-radio value="seats_io"
                    >Seat Layout <b>(seats.io)</b></b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col v-if="form.event_seating_type == 'Y'" cols="12" md="6"
              ><b-form-group label="Seat Layout">
                <v-select
                  v-model="form.sl_id"
                  placeholder="Select Seat Layout"
                  label="seat_layout_name"
                  value="sl_id"
                  :reduce="(layout) => layout.sl_id"
                  :options="seatLayoutListArray"
                >
                  <template slot="selected-option" slot-scope="option">
                    <div class="flex">
                      {{ option.seat_layout_name }} (SLID - {{ option.sl_id }})
                    </div>
                  </template>
                  <template slot="option" slot-scope="option">
                    <span class="fa" :class="option.icon"></span>
                    {{ option.seat_layout_name }} (SLID - {{ option.sl_id }})
                  </template>
                </v-select>
              </b-form-group></b-col
            >

            <b-col cols="12" md="12">
              <b-card
                v-for="(item, index) in form.event_sch_array"
                :key="index"
                bg-variant="light"
                header-bg-variant="primary"
                header-text-variant="white"
                :header="'Schedule ' + (index + 1)"
              >
                <b-card-text class="mt-1">
                  <b-row>
                    <b-col
                      v-if="form.event_seating_type == 'seats_io'"
                      cols="12"
                      md="4"
                      ><b-form-group label="Seats Io Event Key">
                        <b-form-input
                          v-model="item.seatsio_eventkey"
                          id="input-default"
                          placeholder="Seats Io Event Key"
                        ></b-form-input> </b-form-group
                    ></b-col>
                    <b-col cols="12" md="2"
                      ><b-form-group label="Schedule Date">
                        <flat-pickr
                          v-model="item.sch_date"
                          placeholder="Schedule Date"
                          class="form-control"
                        /> </b-form-group></b-col
                    ><b-col cols="12" md="2"
                      ><b-form-group label="Schedule Time">
                        <flat-pickr
                          v-model="item.sch_time"
                          :config="config"
                          placeholder="Schedule Time"
                          class="form-control"
                        /> </b-form-group></b-col
                    ><b-col cols="12" md="3"
                      ><b-form-group label=" Max Seating Capacity">
                        <b-form-input
                          v-model="item.sch_max_capacity"
                          type="number"
                          id="input-default"
                          placeholder=" Max Seating Capacity"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Schedule Status">
                        <b-form-checkbox v-model="item.sch_is_active" switch>{{
                          item.sch_is_active == true ? "Active" : "Disabled"
                        }}</b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="form.event_id"
                      cols="12"
                      md="3"
                      class="d-flex align-items-center text-primary"
                    >
                      <span
                        v-if="form.event_seating_type == 'Y'"
                        style="text-decoration: underline"
                        class="cursor-pointer"
                        @click="showSeatLayoutModal(item.event_sch_id)"
                        >Delete / Block / Release Seats</span
                      >
                    </b-col>
                  </b-row>

                  <b-row v-if="item.sch_seat_type_array.length">
                    <b-col cols="12" md="12">
                      <b-table-simple bordered responsive>
                        <b-thead>
                          <b-tr>
                            <b-th>Seat Type</b-th>
                            <b-th>Availabe Seats</b-th>
                            <b-th>Price Per Seat</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr
                            v-for="(
                              subItem, subIndex
                            ) in item.sch_seat_type_array"
                            :key="subIndex"
                          >
                            <b-td>
                              <v-select
                                v-model="subItem.sct_id"
                                placeholder="Select Seat Type"
                                label="seat_class_name"
                                value="sct_id"
                                :reduce="(seat) => seat.sct_id"
                                :options="seatTypeListArray"
                              />
                            </b-td>
                            <b-td>
                              <b-form-input
                                @input="checkMaxSeats(item, index)"
                                v-model="subItem.available_seats"
                                id="input-default"
                                type="number"
                                placeholder="Available Seats"
                              ></b-form-input>
                            </b-td>
                            <b-td>
                              <b-form-input
                                v-model="subItem.price_per_seat"
                                id="input-default"
                                type="number"
                                placeholder="Price Per Seat"
                              ></b-form-input
                            ></b-td>
                          </b-tr>
                          <b-tr class="text-center">
                            <b-td colspan="3"
                              ><b-button
                                size="sm"
                                @click="
                                  item.sch_seat_type_array.push({
                                    sct_id: null,
                                    available_seats: 1,
                                    price_per_seat: 0,
                                  })
                                "
                                variant="link"
                                >Add More Seat Type</b-button
                              ></b-td
                            >
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols="12" md="12">
              <b-button
                v-if="form.event_seating_type"
                size="sm"
                @click="
                  form.event_sch_array.push({
                    sch_date: null,
                    seatsio_eventkey: null,
                    sch_time: null,
                    sch_max_capacity: 1,
                    sch_is_active: true,
                    sch_seat_type_array:
                      form.event_seating_type != 'Y'
                        ? [
                            {
                              sct_id: null,
                              available_seats: 1,
                              price_per_seat: 0,
                            },
                          ]
                        : [],
                  })
                "
                variant="outline-primary"
                >Add More Schedule</b-button
              >
            </b-col>
            <b-col class="mt-2" cols="12" md="4">
              <b-card-group deck>
                <b-card bg-variant="info" text-variant="black">
                  <b-card-text>
                    <b-form-group label="Settings">
                      <b-form-checkbox v-model="form.event_is_active" switch
                        ><b>Is Active</b></b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="form.event_booking_active"
                        switch
                        ><b>Booking Active</b></b-form-checkbox
                      >
                      <b-form-checkbox v-model="form.event_is_private" switch
                        ><b>Is Private</b></b-form-checkbox
                      >
                    </b-form-group>
                  </b-card-text>
                </b-card>
              </b-card-group>
            </b-col>
            <b-col class="mt-3" md="6" offset-md="3">
              <b-button block variant="primary" @click="onSubmit"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  GetCinemaListService,
  GetGenreListService,
  GetLanguageListService,
  GetSeatTypeListService,
  AddUpdateEventService,
  GetEventListService,
  GetSeatLayoutsListService,
  GetUserListService,
  GetSeatLayoutService,
  GetEventBlockedSeatsService,
  AddEditBlockedSeatsService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { fabric } from "fabric-with-gestures";
import zlib from "zlib";

export default {
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      form: {
        event_id: null,
        event_cinema_id: null,
        event_name: null,
        event_start_date: null,
        event_end_date: null,
        event_age_limit: null,
        event_prefix_code: "TKT",
        event_genre_ids: null,
        event_language_ids: null,
        sl_id: null,
        event_image_small: null,
        event_image_medium: null,
        event_image_large: null,
        event_short_description: null,
        event_long_description: null,
        event_tnc: null,
        event_seating_type: null,
        seatsio_eventkey: null,
        event_sch_array: [],
        event_is_active: false,
        event_booking_active: false,
        event_is_private: false,
        event_managers_ids: null,
        selectable_max_seats: 1,
        event_booking_fees: 0,
        type: null,
      },
      image_small_model: null,
      image_medium_model: null,
      image_large_model: null,
      config: {
        wrap: true,
        enableTime: true,
        noCalendar: true,
        defaultDate: null,
        minuteIncrement: "1",
        time_24hr: true,
      },
      cinemaListArray: [],
      genreListArray: [],
      userListArray: [],
      languageListArray: [],
      seatTypeListArray: [],
      seatLayoutListArray: [],

      seatLayoutJson: null,
      selectedSeats: [],
      selectedSeatsArray: [],
      allBlockedSeats: [],
      adminBlockedSeats: [],

      currentEventSchId: null,
    };
  },
  components: {
    vSelect,
    flatPickr,
    quillEditor,
  },
  async beforeMount() {
    if (
      this.$route.query &&
      this.$route.query.type &&
      this.$route.query.type == "movie"
    ) {
      this.form.type = "movie";
    } else if (
      this.$route.query &&
      this.$route.query.type &&
      this.$route.query.type == "event"
    ) {
      this.form.type = "event";
    } else {
      window.history.go(-1);
    }
    this.getUserList();
    this.getGenreList();
    this.getLanguageList();
    await this.getCinemaList();

    await this.getSeatTypeList();
    await this.getSeatLayoutsList();

    if (this.$route.query && this.$route.query.event_id) {
      await this.getEventById(this.$route.query.event_id);
    }
  },
  methods: {
    async showSeatLayoutModal(event_sch_id) {
      if (!this.form.sl_id) return;
      this.$bvModal.show("seat-layout-modal");
      this.currentEventSchId = event_sch_id;

      // await this.getSeatLayout();
      this.getBlockedSeats(event_sch_id);
    },

    hideSeatLayoutModal() {
      this.$bvModal.hide("seat-layout-modal");
      this.resetSeatLayout();
    },

    async getSeatLayout() {
      await GetSeatLayoutService({ sl_id: this.form.sl_id })
        .then((response) => {
          if (response && response.data && response.data.status) {
            this.seatLayoutJson =
              response.data.Records.data[0].seat_layout_data;
            this.createCanvas();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.log("done");
        });
    },

    createCanvas() {
      const vm = this; // inside canvas event listeners 'this' key word will refer to canvas object, use vm instead.
      if (this.seatLayoutJson) {
        let panning = false;

        let tempSelectedSeats = [];
        let tempBlockedSeats = [];

        let tempSelectedSeatsNames = [];
        let tempSelectedSeatsArray = [];
        let lastSelectedGroupId = null;
        let decodedJson = zlib
          .inflateSync(Buffer.from(this.seatLayoutJson, "base64"))
          .toString();

        let parsedJson = JSON.parse(decodedJson);

        let canvas = new fabric.Canvas(document.getElementById("canvasId"), {});

        canvas.setActiveObject(canvas.item(0));

        canvas.setWidth(window.innerWidth);
        canvas.setHeight(600);

        fabric.util.enlivenObjects(parsedJson.objects, (objs) => {
          objs.forEach((itemMain) => {
            let selectedGroup = itemMain.getObjects();

            if (selectedGroup) {
              for (let item of selectedGroup) {
                if (item.visible == false) {
                  console.log(item, "item");
                  item.visible = true;
                  // item.fill = "";
                }
                if (item.type == "i-text") {
                  if (item.fill != "#000") {
                    item.fontSize = 7.5;
                  } else {
                    item.fontSize = 9;
                  }

                  item.fontWeight = "bold";
                }
                if (item.type == "rect") {
                  item.backgroundColor = "";
                  item.stroke = "";
                }
                if (item.type != "circle") {
                  item.selectable = false;
                  item.evented = false;
                }

                if (item.type == "circle") {
                  for (let blocked of this.allBlockedSeats) {
                    if (
                      item.colName == blocked.column_name &&
                      item.rowName == blocked.row_name &&
                      item.groupId == blocked.seat_group_id &&
                      item.seatType == blocked.seat_type
                    ) {
                      item.fill = "grey";
                      item.selectable = false;
                      item.evented = false;
                    }
                  }

                  // const tempBlockedSeats = [];

                  for (let blocked of this.adminBlockedSeats) {
                    if (
                      item.colName == blocked.column_name &&
                      item.rowName == blocked.row_name &&
                      item.groupId == blocked.seat_group_id &&
                      item.seatType == blocked.seat_type
                    ) {
                      item.fill = "red";
                      item.selectable = true;

                      tempBlockedSeats.push(
                        item.seatType +
                          "-" +
                          item.rowName +
                          item.colName +
                          item.groupId
                      );
                    }
                  }

                  tempSelectedSeats = [
                    ...tempSelectedSeats,
                    ...tempBlockedSeats,
                  ];

                  if (
                    tempSelectedSeats.includes(
                      item.seatType +
                        "-" +
                        item.rowName +
                        item.colName +
                        item.groupId
                    )
                  ) {
                    item.fill = "#1ea83c";
                  }

                  // only to make blocked seats red.
                  if (
                    tempBlockedSeats.includes(
                      item.seatType +
                        "-" +
                        item.rowName +
                        item.colName +
                        item.groupId
                    )
                  ) {
                    item.fill = "red";
                  }
                }
                item.dirty = true;

                item.lockMovementX = true;
                item.lockMovementY = true;
                item.hasBorders = false;
                item.hasControls = false;
                canvas.add(item);
              }
            }
          });
        });

        var canvaWidth = canvas.getWidth();

        // canvas.viewportTransform[4] = canvaWidth / 2 - 650;
        canvas.viewportTransform[5] = 0;

        console.log(canvas.getZoom(), "canvaWidth", canvaWidth);
        if (canvaWidth >= 1280) {
          canvas.zoomToPoint({ x: 200, y: 350 }, 0.5);
        } else if (canvaWidth >= 700) {
          canvas.zoomToPoint({ x: 200, y: 350 }, 0.4);
        } else if (canvaWidth >= 500) {
          canvas.zoomToPoint({ x: 200, y: 350 }, 0.4);
        } else {
          canvas.zoomToPoint({ x: 200, y: 350 }, 0.4);
        }

        canvas.on("mouse:down", function (options) {
          panning = true;
          let item = options.target;

          if (item) {
            if (item.type == "circle") {
              panning = false;
              if (
                item.type == options.target.type &&
                item.colName == options.target.colName &&
                item.rowName == options.target.rowName &&
                item.groupId == options.target.groupId
              ) {
                if (item.rowName && item.colName) {
                  if (
                    !tempSelectedSeats.includes(
                      item.seatType +
                        "-" +
                        item.rowName +
                        item.colName +
                        item.groupId
                    )
                  ) {
                    if (item.groupId == "40") {
                      item.price = 30;
                    }

                    let seatObj = {
                      seatUniqueId: item.groupId + item.rowName + item.colName,
                      seatGroupId: item.groupId,
                      seatRowName: item.rowName,
                      seatColName: item.colName,
                      seatPrice: parseFloat(item.price),
                      seatType: item.seatType,
                    };
                    tempSelectedSeatsArray.push(seatObj);
                    tempSelectedSeatsNames.push(
                      item.seatType + "-" + item.rowName + item.colName
                    );

                    tempSelectedSeats.push(
                      item.seatType +
                        "-" +
                        item.rowName +
                        item.colName +
                        item.groupId
                    );

                    item.fill = "#1ea83c";
                  } else {
                    tempSelectedSeatsNames = tempSelectedSeatsNames.filter(
                      (tempseatName) =>
                        tempseatName !==
                        item.seatType + "-" + item.rowName + item.colName
                    );

                    tempSelectedSeatsArray = tempSelectedSeatsArray.filter(
                      (tempSeatObj) =>
                        tempSeatObj.seatUniqueId !==
                        item.groupId + item.rowName + item.colName
                    );

                    tempSelectedSeats = tempSelectedSeats.filter(
                      (tempseat) =>
                        tempseat !==
                        item.seatType +
                          "-" +
                          item.rowName +
                          item.colName +
                          item.groupId
                    );

                    options.target.fill = "#fff";
                  }
                }
              } else {
              }

              vm.selectedSeats = [...tempSelectedSeatsNames];
              vm.selectedSeatsArray = [...tempSelectedSeatsArray];
            } else {
              console.log("item type is not circle");
            }

            item.dirty = true;
            item.lockMovementX = true;
            item.lockMovementY = true;
            item.hasBorders = false;
            item.hasControls = false;
            canvas.renderAll();
          }
        });

        canvas.on("mouse:wheel", function (opt) {
          canvas.selection = false;
          // if (opt.target && opt.target.type) {
          let delta = opt.e.deltaY;
          let zoom = canvas.getZoom();

          zoom = zoom - delta / 400;
          if (zoom > 2) zoom = 2;
          if (zoom < 0.2) zoom = 0.2;

          canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
          opt.e.preventDefault();
          opt.e.stopPropagation();
          // }
        });

        // document.getElementById("plus").addEventListener(
        //   "click",
        //   function () {
        //     let centerPointNew = new fabric.Point(
        //       canvas.getWidth() / 2,
        //       canvas.getHeight() / 2
        //     );
        //     let zoomNew = canvas.getZoom();
        //     if (zoomNew < 2) {
        //       canvas.zoomToPoint(centerPointNew, zoomNew + 0.1);
        //     }
        //   },
        //   false
        // );
        // document.getElementById("minus").addEventListener(
        //   "click",
        //   function () {
        //     let centerPointNew = new fabric.Point(
        //       canvas.getWidth() / 2,
        //       canvas.getHeight() / 2
        //     );
        //     let zoomNew = canvas.getZoom();
        //     if (zoomNew > 0.4) {
        //       canvas.zoomToPoint(centerPointNew, zoomNew - 0.1);
        //     }
        //   },
        //   false
        // );

        canvas.on("mouse:up", function (e) {
          panning = false;
        });

        canvas.on("mouse:move", function (e) {
          canvas.selection = false;
          if (panning && e && e.e) {
            var units = 10;
            if (e.e.movementX) {
              var delta = new fabric.Point(e.e.movementX, e.e.movementY);
              canvas.relativePan(delta);
            }
          }
        });

        canvas.on("mouse:over", function (e) {
          canvas.hoverCursor = "pointer";
        });
        var pausePanning;
        var zoomStartScale;
        var currentX;
        var currentY;
        var xChange;
        var yChange;
        var lastX;
        var lastY;
        canvas.on({
          "touch:gesture": function (e) {
            canvas.selection = false;

            if (!panning && e.e.touches && e.e.touches.length == 2) {
              // panning = true;
              //var point = new fabric.Point(e.self.x, e.self.y);

              let delta = e.e.deltaY;
              let zoom = canvas.getZoom();

              zoom = zoom - delta / 400;
              if (zoom > 2) zoom = 2;
              if (zoom < 0.4) zoom = 0.4;

              canvas.zoomToPoint({ x: e.self.x, y: e.self.y }, zoom);
              opt.e.preventDefault();
              opt.e.stopPropagation();
              // if (e.self.state == "start") {
              //   zoomStartScale = canvas.getZoom();
              // }
              // var delta = zoomStartScale * e.self.scale;
              // canvas.zoomToPoint(point, delta);

              // panning = false;
            }
          },
          "object:selected": function () {
            panning = true;
          },
          "selection:cleared": function () {
            panning = false;
          },
          "touch:drag": function (e) {
            canvas.selection = false;

            if (panning && undefined != e.self.x && undefined != e.self.x) {
              currentX = e.self.x;
              currentY = e.self.y;
              xChange = currentX - lastX;
              yChange = currentY - lastY;

              if (
                Math.abs(currentX - lastX) <= 50 &&
                Math.abs(currentY - lastY) <= 50
              ) {
                var delta = new fabric.Point(xChange, yChange);
                canvas.relativePan(delta);
              }

              lastX = e.self.x;
              lastY = e.self.y;
            }
          },
        });
      }
    },

    async getBlockedSeats(event_sch_id) {
      this.selectedSeatsArray = [];
      this.adminBlockedSeats = [];
      this.allBlockedSeats = [];

      await GetEventBlockedSeatsService({
        event_id: this.form.event_id,
        event_sch_id: event_sch_id,
      })
        .then(async (response) => {
          if (response && response.data && response.data.status) {
            this.allBlockedSeats = response.data.Records.customerBlockedSeats;
            this.adminBlockedSeats = response.data.Records.adminBlockedSeats;
            this.selectedSeatsArray = this.adminBlockedSeats.map((seat) => ({
              seatUniqueId:
                seat.seat_group_id + seat.row_name + seat.column_name,
              seatGroupId: seat.seat_group_id,
              seatRowName: seat.row_name,
              seatColName: seat.column_name,
              seatPrice: 0,
              seatType: seat.seatType,
            }));

            await this.getSeatLayout();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // setIsLoading(false);
          console.log("done");
        });
    },

    // ----------

    resetSeatLayout() {
      this.selectedSeatsArray = [];
      this.currentEventSchId = null;
    },

    async saveModifiedSeatLayout() {
      this.loading = true;

      const payload = {
        seatsArray: this.selectedSeatsArray,
        event_sch_id: this.currentEventSchId,
        event_id: this.form.event_id,
      };

      console.log({ payload });

      try {
        const response = await AddEditBlockedSeatsService(payload);
        if (response.data.status) {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
        } else {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }

      this.hideSeatLayoutModal();
    },

    async checkMaxSeats(item, index) {
      let getSch = this.form.event_sch_array[index];

      if (getSch) {
        console.log(getSch);
        let getSchSeats = getSch.sch_seat_type_array;
        let addedSeats = 0;
        for (let i of getSchSeats) {
          addedSeats += parseFloat(i.available_seats ? i.available_seats : 0);
        }

        if (
          parseFloat(addedSeats) >
          parseFloat(item.sch_max_capacity ? item.sch_max_capacity : 0)
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Max Seating Capacity exceeded.!",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        console.log(addedSeats, "addedSeats");
      }
    },

    async onSelectSeatingType() {
      this.form.event_sch_array = [];
      if (
        this.form.event_seating_type == "N" ||
        this.form.event_seating_type == "seats_io"
      ) {
        this.form.sl_id = null;
        let sch_obj = {
          sch_date: null,
          seatsio_eventkey: null,
          sch_time: null,
          sch_max_capacity: 1,
          sch_is_active: true,
          sch_seat_type_array: [
            {
              sct_id: null,
              available_seats: 1,
              price_per_seat: 0,
            },
          ],
        };
        this.form.event_sch_array.push(sch_obj);
      } else if (this.form.event_seating_type == "Y") {
        let sch_obj = {
          sch_date: null,
          seatsio_eventkey: null,
          sch_time: null,
          sch_max_capacity: 1,
          sch_is_active: true,
          sch_seat_type_array: [],
        };
        this.form.event_sch_array.push(sch_obj);
      }
    },
    async onSubmit() {
      if (!this.form.type) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please Provide Form Type",
            icon: "EditIcon",
            variant: "danger",
          },
        });
        return;
      }

      try {
        this.form.event_is_active =
          this.form.event_is_active == true ? "Y" : "N";
        this.form.event_booking_active =
          this.form.event_booking_active == true ? "Y" : "N";
        this.form.event_is_private =
          this.form.event_is_private == true ? "Y" : "N";
        for (let i of this.form.event_sch_array) {
          i.sch_is_active = i.sch_is_active == true ? "Y" : "N";
        }

        this.loading = true;
        const response = await AddUpdateEventService(this.form);
        if (response.data.status) {
          this.loading = false;

          if (
            this.$route.query &&
            this.$route.query.type &&
            this.$route.query.type == "movie"
          ) {
            this.$router.push({
              name: "movielist",
            });
          } else if (
            this.$route.query &&
            this.$route.query.type &&
            this.$route.query.type == "event"
          ) {
            this.$router.push({
              name: "eventlist",
            });
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
        } else {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        this.loading = false;

        console.log(error);
      }
    },
    async getEventById(event_id) {
      try {
        const response = await GetEventListService({
          event_id: event_id ? event_id : 0,
        });

        if (response.data.status) {
          if (response.data && response.data.Records) {
            let eventArray = response.data.Records[0];
            Object.keys(eventArray).map((x) => {
              this.form[x] = eventArray[x];
              if (x == "event_is_active") {
                this.form[x] = eventArray[x] == "Y" ? true : false;
              }
              if (x == "event_booking_active") {
                this.form[x] = eventArray[x] == "Y" ? true : false;
              }
              if (x == "event_is_private") {
                this.form[x] = eventArray[x] == "Y" ? true : false;
              }

              for (let i of this.form.event_sch_array) {
                i.sch_is_active = i.sch_is_active == "Y" ? true : false;
              }
            });
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getUserList() {
      try {
        const response = await GetUserListService({
          limit: 5000,
        });
        if (response.data.status) {
          this.userListArray = response.data.Records.data;
          this.userListArray = this.userListArray.filter(
            (item) => item.role_id == 2
          );
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSeatLayoutsList() {
      try {
        const response = await GetSeatLayoutsListService({
          limit: 10000,
        });

        if (response.data.status) {
          this.seatLayoutListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCinemaList() {
      try {
        const response = await GetCinemaListService({
          limit: 10000,
        });

        if (response.data.status) {
          this.cinemaListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getGenreList() {
      try {
        const response = await GetGenreListService({
          limit: 10000,
        });
        if (response.data.status) {
          this.genreListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getLanguageList() {
      try {
        const response = await GetLanguageListService({
          limit: 10000,
        });
        if (response.data.status) {
          this.languageListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSeatTypeList() {
      try {
        const response = await GetSeatTypeListService({
          limit: 10000,
        });
        if (response.data.status) {
          this.seatTypeListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onFileUpload(uploadVModel, uploadVModelValue) {
      this.loading = true;
      try {
        const url = this.BASE_URL + "/admin/uploadimage";
        const formData = new FormData();
        formData.append("image", uploadVModelValue);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            authorization: sessionStorage.getItem("access_token"),
            "x-access-token": sessionStorage.getItem("access_token"),
          },
        };
        const response = await axios.post(url, formData, config);
        if (response.data.message) {
          if (uploadVModel == "small") {
            this.form.event_image_small = response.data.path;
          } else if (uploadVModel == "medium") {
            this.form.event_image_medium = response.data.path;
          } else if (uploadVModel == "large") {
            this.form.event_image_large = response.data.path;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something Went Wrong!",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error in UploadFile ", err);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.modal-dialog,
.modal-content {
  height: 700px;
}

.modal-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: 700px;
  overflow-y: scroll;
}

.canvas-container {
  height: 600px;
  max-height: 600px;
  position: relative;
}

.canvas-actions {
  position: absolute;
  top: 57%;
  right: 1.5%;
  width: 400px;
}

.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.red {
  background-color: red;
}

.grey {
  background-color: grey;
}

.green {
  background-color: #1ea83c;
}
</style>
